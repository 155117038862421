import React, { useEffect, useState } from "react"

import FullPageLoader from "../components/common/FullPageLoader"
import { Card, Col, Container } from "react-bootstrap"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import "react-toastify/dist/ReactToastify.min.css"
import NotFoundPage from "./404"
import { graphql } from "gatsby"
import Logs from "../modules/Logs"
import dayjs from "dayjs"
import "dayjs/locale/ar"
import Seo from "../components/seo"
import shipperExpressLogo from "images/shipper-express-logo.svg"

const isBrowser = typeof window !== "undefined"

function Page() {
  const { t } = useTranslation("tracking")
  const { i18n } = useI18next()

  useEffect(() => {
    console.log(i18n.resolvedLanguage)
    if (i18n.resolvedLanguage === "ar") {
      document.body.style.direction = "rtl"
      document.body.style.fontFamily = "Noto Kufi Arabic"
    }
    dayjs.locale(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage])

  const [shipment, setShipment] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if (isBrowser) {
      try {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const shipment_uuid = urlParams.get("id")
        const REVIEW_URL = new URL(
          `/api/shipment-tracking/${shipment_uuid}`,
          process.env.GATSBY_APP_HOST
        ).toString()

        setLoading(true)
        const res = await fetch(REVIEW_URL)
        const content = await res.json()
        setShipment(content)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const tracking_logs = shipment?.tracking_numbers[0]?.logs

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) {
    return <FullPageLoader />
  }

  if (!shipment) {
    return <NotFoundPage />
  }

  if (shipment.tracking_numbers === 0) {
    return <NotFoundPage />
  }

  return (
    <>
      <Seo />
      <Container>
        <div className="g-0 min-vh-100 py-2">
          <div className={"text-center py-2"}>
            <img
              src={shipperExpressLogo}
              style={{ width: "220px", maxWidth: "100%" }}
            />
          </div>
          <Card>
            <Card.Header className="py-3 text-center bg-card-gradient text-white">
              <h4 className=" mb-0">{t("Order tracking")}</h4>
            </Card.Header>
            <Card.Body>
              <Col className="mb-2">
                <h5 className="py-4">{t("Here are the shipment details")}</h5>
                <div className="custom-scroller" style={{ overflowX: "auto" }}>
                  <table className="table table-bordered fs-0 border mb-0">
                    <thead className="bg-table-head-gradient text-white">
                      <tr>
                        <th className="text-nowrap fs--1 p-3">
                          {t("Product")}
                        </th>
                        <th className="align-middle text-center text-nowrap fs--1 py-3 px-4">
                          {t("Quantity")}
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "none" }}>
                      {shipment.details.map(({ product, qte }, index) => {
                        const mediaUrl = product.is_variation
                          ? product?.variation_media?.original_url
                          : product?.media?.[0]?.original_url

                        return (
                          <tr key={index}>
                            <td className="align-middle">
                              <div className="d-flex align-items-center">
                                <div className="variation-image-container md">
                                  <div className="d-block h-sm-100">
                                    <div
                                      style={{
                                        backgroundImage: `url(${mediaUrl})`,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        height: "64px",
                                        width: "64px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column text-truncate ps-1 pe-4">
                                  <h5 className="text-800 mb-0 text-wrap fs-0">
                                    {product.name}
                                  </h5>
                                  {product.is_variation && (
                                    <div className="d-flex text-nowrap mt-1">
                                      <div className="d-flex flex-column text-nowrap">
                                        {product.options.map((row, index) => (
                                          <div className="d-flex" key={index}>
                                            <span className="fs--1 text-700 me-1 text-capitalize">
                                              {row.name}:{" "}
                                            </span>
                                            <span className="fs--1 ">
                                              {row.value}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle fw-bold text-center">
                              {qte}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <Logs logs={tracking_logs} />
              </Col>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["tracking"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Page
