import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import dayjs from "dayjs"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faBoxesPacking,
  faCartShopping,
  faCheck,
  faDollyBox,
  faFileArchive,
  faRedo,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"

// var utc = require("dayjs/plugin/utc")
// var timezone = require("dayjs/plugin/timezone")

import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const timeZone = dayjs.tz.guess()

export default function Logs({ logs }) {
  const { t } = useTranslation("tracking")

  const flatenedArray = logs
    ?.filter(log => {
      if (log.action == "shipment-status-update-feedback") {
        return [
          "Pending",
          "Picked up",
          "On going",
          "Delivered",
          "Not definitive return",
          "Verifying",
          "Definitive return",
        ].includes(log.status)
      }
    })
    ?.map(log => tracking_number_logs_template(log))

  return (
    <>
      <h5 className="py-3 my-2">{t("Tracking feedback")}</h5>
      <Col className="mt-3">
        {flatenedArray ? (
          <FullfilmentSteps flatenedArray={flatenedArray} />
        ) : (
          <div className="alert-warning alert">
            {t("Sorry, No logs available yet")}
          </div>
        )}
      </Col>
    </>
  )
}

function FullfilmentSteps({ flatenedArray }) {
  return flatenedArray
    .sort((a, b) => dayjs(b.created_at) - dayjs(a.created_at))
    .map((log, index) => (
      <Step key={index} log={log} isLast={index === flatenedArray.length - 1} />
    ))
}

function Step({ log: { title, description, icon, created_at }, isLast }) {
  const { t } = useTranslation("tracking")
  console.log({ created_at })
  return (
    <Row
      className={classNames(
        "g-3 recent-activity-timeline recent-activity-timeline-primary recent-activity-timeline-past completed",
        {
          "pb-card": !isLast,
          "recent-activity-timeline-no-line": isLast,
        }
      )}
    >
      <Col xs="auto" className="">
        <div className="ps-2">
          <div
            className={classNames(
              "icon-item rounded-circle bg-gradient shadow-none bg-200 border border-primary"
            )}
          >
            <FontAwesomeIcon icon={icon} className="text-primary" />
          </div>
        </div>
      </Col>
      <Col>
        <Row
          className={classNames("g-3  pb-card", { "border-bottom": !isLast })}
        >
          <Col>
            <h6 className="mb-1 text-800">{t(title)}</h6>
            <h6 className="fs-0 text-primary text-800 mb-0">
              {t(description.template, description.variables)}
            </h6>
            {/* <span className="fs--2 mt-2 text-600">{creator}</span> */}
          </Col>
          <Col xs="auto" className="text-right text-500">
            {dayjs.utc(created_at).tz(timeZone).fromNow()}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const tracking_number_logs_template = log => {
  switch (log.action) {
    case "generate-shipment-tracking-number":
      return {
        title: "Order prepared",
        description: {
          template: `Order Submitted to delivery company with tracking number {{tracking_number}}`,
          variables: {
            // tracking_number: order.tracking_numbers[0].shipment_tracking_number,
          },
        },
        icon: faBoxesPacking,
        created_at: log.created_at,
        // creator: log.creator
      }

    case "shipment-status-update-feedback": {
      let statusTemplate = {
        description: {
          template: "",
          icon: faFileArchive,
        },
      }
      switch (log.status) {
        case "Pending":
          statusTemplate.description.template = `Order is awaiting to be received by the delivery company.`
          statusTemplate.icon = faBoxesPacking
          break
        case "Picked up":
          statusTemplate.description.template = `Order has been picked up by the delivery company.`
          statusTemplate.icon = faDollyBox
          break
        case "On going":
          statusTemplate.description.template = `Order is being delivered.`
          statusTemplate.icon = faTruckFast
          break
        case "Delivered":
          statusTemplate.description.template = `Order has been delivered successfully.`
          statusTemplate.icon = faCheck
          break
        case "Not definitive return":
          statusTemplate.description.template = `The package has been returned to the delivery company warehouse.`
          statusTemplate.icon = faRedo
          break
        case "Definitive return":
          statusTemplate.description.template = `Order delivery has been marked as delivery failed.`
          statusTemplate.icon = faBan
          break
        default:
          statusTemplate.description.template = log.status
      }

      return {
        title: "Tracking feedback",
        description: statusTemplate.description,
        icon: statusTemplate.icon,
        created_at: log.created_at,
        // creator: log.creator
      }
    }
    default:
      return {
        title: log.action,
        description: log.status,
        icon: faFileArchive,
        created_at: log.created_at,
        // creator: log.creator
      }
  }
}

Logs.propTypes = {
  logs: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
}

FullfilmentSteps.propTypes = {
  flatenedArray: PropTypes.array,
}

Step.propTypes = {
  log: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
}
